var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Tif")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("General")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Básico")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Proveedores")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"btn-group float-right"},[(
                        _vm.$store.getters.can('tif.proveedores.edit') ||
                          _vm.$store.getters.can('tif.proveedores.create')
                      )?_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.direccionar()}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e()])])])]),_c('div',{staticClass:"card-body p-0 table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",attrs:{"id":"proveedores"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_vm._v(" # Proveedor "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.id),expression:"filtros.id"}],staticClass:"form-control form-control-sm",attrs:{"type":"number"},domProps:{"value":(_vm.filtros.id)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "id", $event.target.value)},function($event){return _vm.getIndex()}]}})]),_c('th',{staticClass:"text-center",staticStyle:{"width":"40%"}},[_vm._v(" Nombre "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.nombre),expression:"filtros.nombre"}],staticClass:"form-control form-control-sm",attrs:{"type":"text"},domProps:{"value":(_vm.filtros.nombre)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "nombre", $event.target.value)},function($event){return _vm.getIndex()}]}})]),_c('th',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_vm._v(" Cantidad Vehículos ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"10%"}},[_vm._v("Acción")])])]),_c('tbody',_vm._l((_vm.proveedores.data),function(proveedor){return _c('tr',{key:proveedor.id},[_c('td',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(proveedor.id))])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(proveedor.nombre))]),_c('td'),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group"},[(
                            _vm.$store.getters.can('tif.proveedores.edit') ||
                              _vm.$store.getters.can('tif.proveedores.create')
                          )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.direccionar(proveedor)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(_vm.$store.getters.can('tif.proveedores.delete'))?_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroy(proveedor)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"card-footer"},[(_vm.proveedores.total)?_c('div',{staticClass:"float-left"},[_c('p',[_vm._v(" Mostrando del "),_c('b',[_vm._v(_vm._s(_vm.proveedores.from))]),_vm._v(" al "),_c('b',[_vm._v(_vm._s(_vm.proveedores.to))]),_vm._v(" de un total: "),_c('b',[_vm._v(_vm._s(_vm.proveedores.total))]),_vm._v(" Registros ")])]):_c('div',{staticClass:"float-left"},[_vm._m(1)]),_c('pagination',{attrs:{"data":_vm.proveedores,"limit":5,"align":"right"},on:{"pagination-change-page":_vm.getIndex}})],1)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Proveedores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"badge badge-danger"},[_vm._v("No hay registros para mostrar")])])
}]

export { render, staticRenderFns }