<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Proveedores</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Proveedores</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="direccionar()"
                        v-if="
                          $store.getters.can('tif.proveedores.edit') ||
                            $store.getters.can('tif.proveedores.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 table-responsive">
                <table
                  id="proveedores"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th class="text-center" style="width:15%;">
                         # Proveedor
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center" style="width:40%;">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center" style="width:15%;">
                        Cantidad Vehículos
                      </th>
                      <th class="text-center" style="width:10%;">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="proveedor in proveedores.data"
                      :key="proveedor.id"
                    >
                      <td class="text-center"><b>{{ proveedor.id }}</b></td>
                      <td class="text-center">{{ proveedor.nombre }}</td>
                      <td></td>
                      <td class="text-center">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="direccionar(proveedor)"
                            v-if="
                              $store.getters.can('tif.proveedores.edit') ||
                                $store.getters.can('tif.proveedores.create')
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(proveedor)"
                            v-if="$store.getters.can('tif.proveedores.delete')"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="proveedores.total">
                  <p>
                    Mostrando del <b>{{ proveedores.from }}</b> al
                    <b>{{ proveedores.to }}</b> de un total:
                    <b>{{ proveedores.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger"
                      >No hay registros para mostrar</span
                    >
                  </p>
                </div>
                <pagination
                  :data="proveedores"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "TifProveedoresIndex",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      filtros: {
        id: null,
        nombre: null,
      },
      proveedores: {},
      cargando: false,
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/proveedores/index?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.proveedores = response.data;
          this.cargando = false;
        });
    },

    direccionar(item = null) {
      let accion = "Crear";
      if (item) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/Tif/TifProveedoresForm",
        params: {
          accion,
          item,
        },
      });
    },

    destroy(proveedor) {
      this.$swal({
        title: "Esta seguro de eliminar el registro ?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tif/proveedores/" + proveedor.id).then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Se eliminó el registro exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
